import React, { useState } from "react";
// assets
import { IoWarning, IoSunny } from "react-icons/io5";

const ResultList = ({ list, index, selectedRisk, exactDuration }) => {
  // state
  const [unitDisplay, setUnitDisplay] = useState(false);

  // toggle unit function
  function toggleUnitDisplay() {
    setUnitDisplay(!unitDisplay);
  }
  return (
    list && (
      <div
        key={index}
        className="text-white rounded-md flex justify-between items-center p-5 w-full font-bold cursor-pointer border-2 border-white border-opacity-30 select-none transition-all"
        onClick={() => toggleUnitDisplay()}
      >
        {list.hour_of_life === exactDuration ? (
          <div>
            {list.hour_of_life}{" "}
            <span style={{ color: `var(--${selectedRisk.color})` }}>HOL</span> *
          </div>
        ) : (
          <div>
            {list.hour_of_life}{" "}
            <span style={{ color: `var(--${selectedRisk.color})` }}>HOL</span>
          </div>
        )}
        <div className="flex justify-between text-sm">
          <div className="mr-4 pr-4 flex items-center gap-3 border-r-2 border-white border-opacity-20">
            <div className="flex flex-col justify-center items-center">
              <IoSunny /> PT
            </div>
            <div>
              <p>
                {list.pt_mcmolL}{" "}
                {unitDisplay ? (
                  <span className="text-gray-400 text-xs">mcmol/L</span>
                ) : null}
              </p>
              <p>
                {list.pt_mgdL}{" "}
                {unitDisplay ? (
                  <span className="text-gray-400 text-xs">mg/dL</span>
                ) : null}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-3">
            <div className="flex flex-col justify-center items-center">
              <IoWarning /> ET
            </div>
            <div>
              <p>
                {list.et_mcmolL}{" "}
                {unitDisplay ? (
                  <span className="text-gray-400 text-xs">mcmol/L</span>
                ) : null}
              </p>
              <p>
                {list.et_mgdL}{" "}
                {unitDisplay ? (
                  <span className="text-gray-400 text-xs">mg/dL</span>
                ) : null}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ResultList;
