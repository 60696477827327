import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "./styles.css";

// assets
import { IoTime } from "react-icons/io5";

// redux
import { useDispatch, useSelector } from "react-redux";
import { addBirthDate, removeBirthDate } from "../../reducers/DateReducer";

export default function BirthDatePicker(props) {
  // redux
  const statusColor = useSelector((state) => state.risk.currentRisk.color);
  const dispatch = useDispatch();
  const savedBirthDate = useSelector((state) => state.selectedDate.birthDate);

  // state
  const [selectDate, setSelectDate] = useState(null);

  function savedDateHandler() {
    if (savedBirthDate) {
      setSelectDate(Date.parse(savedBirthDate));
    }
  }

  useEffect(() => {
    props.setBirthDate(selectDate);
    savedDateHandler();
  }, [selectDate, savedBirthDate]);

  return (
    <div
      className={
        !selectDate
          ? `text-gray-400 text-sm rounded-md flex justify-between items-center pr-5 w-full font-bold border-2 border-white border-opacity-30 select-none`
          : `text-white text-sm rounded-md flex justify-between items-center pr-5 w-full font-bold border-2 border-${statusColor} select-none`
      }
      style={!selectDate ? null : { borderColor: `var(--${statusColor})` }}
    >
      <DatePicker
        selected={selectDate}
        onChange={(date) => {
          setSelectDate(date);
          dispatch(addBirthDate(date));
        }}
        timeInputLabel="Time:"
        dateFormat="dd/MM/yyyy h:mm aa"
        showTimeSelect
        timeIntervals={10}
        placeholderText="Date/time of birth"
        maxDate={new Date()}
      />
      <span>
        {props.birthDate ? (
          <button
            className="text-gray-400 whitespace-nowrap"
            onClick={() => {
              setSelectDate(null);
              dispatch(removeBirthDate());
            }}
          >
            reset birth date
          </button>
        ) : (
          <IoTime size={"1rem"} />
        )}
      </span>
    </div>
  );
}
