// react router
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./pages/Home";
import Result from "./pages/Result";

function App() {
  return (
    <>
      <div className="flex flex-col items-center">
        <Router basename={process.env.PUBLIC_URL}>
        <Header />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/result" exact component={Result} />
          </Switch>
        </Router>
      </div>
    </>
  );
}

export default App;
