import { createSlice } from "@reduxjs/toolkit";

const initialRiskState = { data: [] };

export const riskSlice = createSlice({
  name: "auth",
  initialState: {
    currentRisk: initialRiskState,
  },
  reducers: {
    addRisk: (state, action) => {
      state.currentRisk = action.payload;
    },
    removeRisk: (state) => {
      state.currentRisk = initialRiskState;
    },
  },
});

export const { addRisk, removeRisk } = riskSlice.actions;

export default riskSlice.reducer;
