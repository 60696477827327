import React from "react";

// redux
import { useSelector } from "react-redux";

// react router
import { useHistory } from "react-router-dom";

export default function Header() {
  // redux
  const selectedRisk = useSelector((state) => state.risk.currentRisk);

  // react router
  const history = useHistory();

  return (
    <>
      <div className="sticky top-0 z-50 bg-primary w-full flex justify-between items-center px-3 md:px-6 py-5">
        <p
          className="whitespace-nowrap text-xl font-bold text-white cursor-pointer"
          onClick={() => history.push("/")}
        >
          Jaundicio
        </p>
        <div className="flex items-center justify-end">
          {selectedRisk.risk ? (
            <>
              <p className="whitespace-nowrap text-base font-medium text-gray-400">
                Risk
              </p>
              <button
                className={`ml-5 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm md:text-base font-medium text-white bg-${selectedRisk.color} cursor-pointer capitalize`}
                onClick={() => history.push("/")}
              >
                {selectedRisk.risk}
              </button>
            </>
          ) : (
            <>
              <p className="whitespace-nowrap hidden md:block text-base font-medium text-gray-400">
                Enquiry?
              </p>
              <a
                className="ml-4 whitespace-nowrap inline-flex items-center justify-center py-2 border border-transparent rounded-md shadow-sm text-sm md:text-base font-medium text-white bg-primary cursor-pointer"
                href="https://wa.me/60196974200"
                target={"blank"}
              >
                Contact Developer
              </a>
            </>
          )}
        </div>
      </div>
    </>
  );
}
