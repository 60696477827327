import React, { useEffect } from "react";

// redux
import { useDispatch } from "react-redux";
import { addRisk, removeRisk } from "../reducers/RiskReducer";

// react router
import { useHistory } from "react-router-dom";

// data
import lowRiskData from "../data/hsi_pt_et_data - low_risk.json";
import moderateRiskData from "../data/hsi_pt_et_data - moderate_risk.json";
import highRiskData from "../data/hsi_pt_et_data - high_risk.json";

export default function Home() {
  // react router
  const history = useHistory();

  // redux
  const dispatch = useDispatch();

  // clear the current branch
  useEffect(() => dispatch(removeRisk()), []);

  // select risk
  function selectRisk(risk, color, data) {
    dispatch(addRisk({ risk: risk, color: color, data: data }));
    history.push("/result");
  }

  return (
    <>
      <div className="flex justify-center items-center w-screen min-h-[calc(100vh-78px)] bg-primary px-3 md:px-6">
        <div className="w-full md:w-2/3 lg:w-2/5 flex flex-col justify-center">
          <p className="text-gray-400 text-lg text-center font-bold mb-5">
            Select risk
          </p>
          <div className="flex flex-col gap-3 w-full">
            <div
              id="Low_Risk_Button"
              className="bg-one text-white rounded-md flex justify-center items-center py-5 w-full font-bold cursor-pointer border-2 border-white border-opacity-30 select-none"
              onClick={() => selectRisk("low", "one", lowRiskData)}
            >
              Low
            </div>
            <div
               id="Moderate_Risk_Button"
              className="bg-two text-white rounded-md flex justify-center items-center py-5 w-full font-bold cursor-pointer border-2 border-white border-opacity-30 select-none"
              onClick={() => selectRisk("moderate", "two", moderateRiskData)}
            >
              Moderate
            </div>
            <div
               id="High_Risk_Button"
              className="bg-three text-white rounded-md flex justify-center items-center py-5 w-full font-bold cursor-pointer border-2 border-white border-opacity-30 select-none"
              onClick={() => selectRisk("high", "three", highRiskData)}
            >
              High
            </div>
          </div>
          <a
            className="text-gray-400 text-xs text-center font-base mt-10 cursor-pointer"
            href="https://mpaeds.my/wp-content/uploads/2018/03/CPG-Management-of-Neonatal-Jaundice-Second-Edition.pdf"
            target={"blank"}
          >
            Refer here to determine the risk
          </a>
        </div>
      </div>
    </>
  );
}
