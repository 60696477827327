import { createSlice } from "@reduxjs/toolkit";

export const dateSlice = createSlice({
  name: "date",
  initialState: {
    birthDate: null,
    labDate: null,
  },
  reducers: {
    addBirthDate: (state, action) => {
      state.birthDate = action.payload;
    },
    addLabDate: (state, action) => {
      state.labDate = action.payload;
    },
    removeBirthDate: (state, action) => {
      state.birthDate = null;
    },
    removeLabDate: (state, action) => {
      state.labDate = null;
    },
  },
});

export const { addBirthDate, addLabDate, removeBirthDate, removeLabDate } =
  dateSlice.actions;

export default dateSlice.reducer;
