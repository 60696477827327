import React, { useEffect, useState } from "react";

// redux
import { useSelector } from "react-redux";

// assets
import BirthDatePicker from "../components/BirthDatePicker";
import moment from "moment";
import LabDatePicker from "../components/LabDatePicker";
import NoDataListing from "../components/NoDataListing";
import ResultList from "../components/ResultList";

export default function Result() {
  // state

  const [birthDate, setBirthDate] = useState(null);
  const [labDate, setLabDate] = useState(null);
  const [displayData, setDisplayData] = useState([]);
  const [displayDuration, setDisplayDuration] = useState(null);
  const [exactDuration, setExactDuration] = useState(null);

  // redux
  const DATA = useSelector((state) => state.risk.currentRisk.data);
  const selectedRisk = useSelector((state) => state.risk.currentRisk);
  const savedDates = useSelector((state) => state.selectedDate.birthDate);

  // calculate hour of life
  function calcHourOfLife() {
    let today = labDate ? moment(labDate) : moment(new Date());
    let end = moment(birthDate);
    if (birthDate) {
      let duration = moment.duration(today.diff(end)).asHours();
      // reformat the duration
      // less than an hour
      if (duration < 1) {
        setDisplayDuration(Math.floor(duration * 60) + " minutes");
      }
      // more than an hour
      else {
        let hour = Math.floor(duration);
        let minute = Math.round((duration - hour) * 60);
        setDisplayDuration(`${hour} hour ${minute} mins`);
      }
      // display data according to the duration
      handleDisplayData(duration);
      setExactDuration(Math.round(duration));
    } else {
      savedDates ? setDisplayData([]) : setDisplayData(DATA);
      setDisplayDuration(null);
      setExactDuration(null);
    }
  }

  // handle display data selection
  function handleDisplayData(duration) {
    if (duration < 6) {
      setDisplayData(DATA.filter((list) => list.hour_of_life === 6));
    } else if (duration > 6 && duration <= 12) {
      setDisplayData(
        DATA.filter((list) => list.hour_of_life >= 6 && list.hour_of_life <= 12)
      );
    } else if (duration > 12 && duration <= 24) {
      setDisplayData(
        DATA.filter(
          (list) => list.hour_of_life >= 12 && list.hour_of_life <= 24
        )
      );
    } else if (duration > 120) {
      setDisplayData(DATA.filter((list) => list.hour_of_life === 120));
    } else {
      setDisplayData(
        DATA.filter(
          (list) =>
            list.hour_of_life >= Math.floor(duration) - 1 &&
            list.hour_of_life <= duration + 1
        )
      );
    }
  }
  console.log("duration", exactDuration);
  console.log("displayData", displayData);

  useEffect(() => {
    calcHourOfLife();
  }, [birthDate, labDate, exactDuration, savedDates, DATA]);

  return (
    <>
      <div className="flex justify-center items-start w-screen min-h-[calc(100vh-78px)] bg-primary px-3 md:px-6">
        <div className="w-full md:w-2/3 lg:w-2/5">
          <div className="flex flex-col gap-3 w-full text-white py-3">
            <div>
              <BirthDatePicker
                birthDate={birthDate}
                setBirthDate={setBirthDate}
              />
            </div>
            {displayDuration ? (
              <>
                <LabDatePicker
                  labDate={labDate}
                  setLabDate={setLabDate}
                  birthDate={birthDate}
                  setBirthDate={setBirthDate}
                />
                <div className="px-5 py-1 text-sm">
                  {displayDuration} of life{" "}
                  <span className="text-white text-opacity-50">
                    - calculated from{" "}
                    {labDate
                      ? moment(labDate).format("D/MM/YY h:mm a")
                      : "now "}
                  </span>
                </div>
              </>
            ) : null}
            {displayData.map((list, index) => (
              <ResultList
                list={list}
                index={index}
                selectedRisk={selectedRisk}
                exactDuration={exactDuration}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
