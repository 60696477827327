import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "./styles.css";

// assets
import { IoTime } from "react-icons/io5";
import moment from "moment";

// redux
import { useDispatch, useSelector } from "react-redux";
import { addLabDate, removeLabDate } from "../../reducers/DateReducer";

export default function LabDatePicker(props) {
  // redux
  const statusColor = useSelector((state) => state.risk.currentRisk.color);
  const dispatch = useDispatch();
  const savedLabDate = useSelector((state) => state.selectedDate.labDate);

  // state
  const [selectDate, setSelectDate] = useState(null);

  function savedDateHandler() {
    if (savedLabDate) {
      setSelectDate(Date.parse(savedLabDate));
    }
  }

  useEffect(() => {
    props.setLabDate(selectDate);
    savedDateHandler();
  }, [selectDate, savedLabDate]);

  return (
    <div
      className={
        !selectDate
          ? `text-gray-400 text-sm rounded-md flex justify-between items-center pr-5 w-full font-bold border-2 border-white border-opacity-30 select-none`
          : `text-white text-sm rounded-md flex justify-between items-center pr-5 w-full font-bold border-2 border-${statusColor} select-none`
      }
      style={!selectDate ? null : { borderColor: `var(--${statusColor})` }}
    >
      <DatePicker
        selected={selectDate}
        onChange={(date) => {
          setSelectDate(date);
          dispatch(addLabDate(date));
        }}
        timeInputLabel="Time:"
        dateFormat="dd/MM/yyyy h:mm aa"
        showTimeSelect
        timeIntervals={10}
        placeholderText="Date/time of TSB taken"
        minDate={props.birthDate}
        maxDate={
          props.birthDate
            ? moment(props.birthDate).add(5, "days").toDate()
            : null
        }
      />
      <span>
        {props.labDate ? (
          <button
            className="text-gray-400 whitespace-nowrap"
            onClick={() => {
              setSelectDate(null);
              dispatch(removeLabDate());
            }}
          >
            reset lab date
          </button>
        ) : (
          <IoTime size={"1rem"} />
        )}
      </span>
    </div>
  );
}
